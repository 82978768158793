import axios from 'axios';
// import encryption from '@/utility/encryption';
import actions from './actions';

jest.mock('@/utility/encryption', () => ({
  encrypt: () => 'EEEE'
}));
jest.mock('axios');

beforeEach(() => {
  axios.post.mockReset();
  axios.get.mockReset();
});

describe('user actions', () => {
  describe('fetchKey', () => {
    it.skip('should call axios with valid url with time stamp', async () => {
      const original = global.Date;
      const MOCK_FUNCTION = {
        getTime: () => 1585553780991,
      };
      global.Date = jest.fn().mockImplementationOnce(() => MOCK_FUNCTION);
      axios.get.mockResolvedValue({ data: '' });
      await actions.fetchKey();

      expect(axios.get).toBeCalledWith('/fcgi-bin/system.key?1585553780991');

      global.Date = original;
    });
  });
  describe('$_login', () => {
    it('should call post with url', async () => {
      axios.post = jest.fn().mockResolvedValue({ data: '' });
      const accountData = {
        userName: 'aaa',
        password: '1111',
        publicKey: 'EEEE',
      };

      const commit = () => {};
      await actions.$_login({ commit }, accountData);

      expect(axios.post).toBeCalledWith('/fcgi-bin/system.login',
        'encode=EEEE',
        { headers: { Authorization: 'Basic EEEE', 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
    });
  });
  describe('logout', () => {
    it('should call axios.post with valid url', async () => {
      axios.post = jest.fn().mockResolvedValue({ data: '' });
      const commit = () => {};

      await actions.logout({ commit });

      expect(axios.post).toBeCalledWith('/fcgi-bin/system.logout');
    });
  });
});
